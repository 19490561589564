import React from 'react'
import PropTypes from 'prop-types'
import Image from '../../images/portfolio-spontanous-ar-01.jpg'
import { graphql } from 'gatsby'

import styles from './chris.module.scss'

const ChrisCard = ({ data }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      <h3> Interested in VR, AR, Machine Learning or Robotics!? </h3>

      <a href={data.dataYaml.instantLink}>
        <img src={Image} className={styles.image}/>
        <h2>Check out Spontanous AR</h2>
      </a>

      <h3> Let’s talk! </h3>

      <a href="mailto:chris@vrational.com">chris@vrational.com</a>
    </div>
  )
}

ChrisCard.propTypes = {
  location: PropTypes.object
}
export default ChrisCard

export const IndexQuery = graphql`
  query {
    dataYaml {
      instantLink
    }
  }
`
